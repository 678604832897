<script>
import moment from 'moment'
import serverConfig from '@/helpers/config';
import { useCookies } from "vue3-cookies";
import Swal from "sweetalert2";
import axios from 'axios';


import documentManagerUT from "@/components/widgets/projects/documentManagerUT";

export default {
  name: "accountingManager",
  props: [
    'ID',
    'data',
  ],
  setup() {
   
    moment.locale('it')

    const { cookies } = useCookies();    
    let tk = 'Bearer '+cookies.get('tk')

    return { tk };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        item:{
          accountingDetails:{
              contract:false,
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          }           
        },



    };
  },
    validations: {
    },
  mounted() {
    
    this.item.accountingDetails = this.data
  },

  methods: {
    convertDate(val){
        this.item.accountingDetails.date =  moment(val).unix()
        this.updateItem()
    },
    updateItem(){
       // this.submitted = true;
        //this.v$.$touch();
       
        axios.put(`${this.UrlServer}projects/${this.ID}`, this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
            this.$emit('getParent')
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
  },
  components: {
    documentManagerUT,
  },

};
</script>

<template>
    <form action="">
      <div class="row mb-50 back-grey">
                <div class="col-lg-12 col-md-12 mb-20">                                                    
                  <div>   
                    <p class="text-muted bold">Note</p>
                      <p class="text-muted">La registrazione amministrativa si intende automaticamente effettuata con la conferma della stipula del contratto</p>
                                                    
                  </div>
                </div>
            </div>
        <div class="row mb-1 back-grey">
            <div class="col-lg-2 col-md-12">
             
                <label for="contractInput" class="form-label">Stipula contratto</label>
                <select class="form-select"  placeholder="Seleziona stato stipula contratto" data-trigger id="contractInput" v-model="item.accountingDetails.contract"  @change="updateItem">
                    <option value="">Seleziona stato Stipula contratto</option>
                    <option value="false">Non avvenuta</option>
                    <option value="true">Avvenuta</option>
                   
                </select>                      
            </div>
            <div class="col-lg-2 col-md-12">
                <label for="dateRegInput" class="form-label">Data stipula contratto</label>
                <input type="date" class="form-control" id="dateRegInput" v-model="item.accountingDetails.dateStr"  @change="convertDate(item.accountingDetails.dateStr)">
            </div>
            <div class="col-lg-2 col-md-12">
                <label for="modPayRegInput" class="form-label">Modalità di pagamento</label>
                <select class="form-select"  placeholder="Seleziona Modalità di pagamento" data-trigger id="modPayRegInput" v-model="item.accountingDetails.modPay"  @change="updateItem">
                    <option value="">Seleziona Modalità di pagamento</option>
                    <option value="bonifico">Bonifico bancario</option>
                   
                </select>                      
            </div>      
            <div class="col-lg-3 col-md-12">
            
            </div>
        </div>
        <div class="row mb-3 back-grey">
            <div class="col-lg-6">
                <label for="responseMSGInput" class="form-label">Note di registrazione (facoltativo)</label>
                  <textarea class="form-control" id="responseMSGInput" rows="3" placeholder="Inserisci eventuali note sul so" v-model="item.accountingDetails.note" @blur="updateItem"></textarea>                       
            </div>
           
            <div class="col-lg-3">
                                    
            </div>      
            <div class="col-lg-3">
              </div>
        </div>
    </form>
    <div class="row mb-3">
        <div class="col-lg-12">
          <template v-if="data">
              <template v-if="data.customer!=null">
                <template v-if="data.customer.length>0">
                 
                  <documentManagerUT 
                    :url="`${this.UrlServer}storage/by/project`"
                    :projectID="data._id" 
                    :data="data" 
                    :bucket="data.customer[0].nomeFascicolo + data.year.toString() + '/' + data.systemSubCategory+'/'" 
                    type="doc-raccolta-dati"
                    refDirectory="raccolta_dati"
                    fileType="multiple" 
                    fileAccept="*" 
                    title="Documentazione impianto"
                    v-on:getParent="getParent()"/>
                </template>
              </template>
            </template> 


        </div>
    </div>




</template>
